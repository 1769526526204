import * as React from 'react';
import { useTabs, TabPanel } from "react-headless-tabs"
import { TabSelector } from './TabSelector';

import {
    container,
    tabContent,
    splitContent,
    text,
    grid,
    grid__column,
    media
} from "../../scss-modules/tabs.module.scss";

export function OilAndGasTabs() {
    const [selectedTab, setSelectedTab] = useTabs([
        'geosciences',
        'drilling',
        'evaluation',
        'wellTesting',
        'completions',
        'fracturing',
        'workoverSnubbing',
        'interventions',
        'subsea',
        'productionPPS',
        'integratedServices',
        'logisticsHSE',
        'mainstreamDownstream'
    ]);

    return (
        <div className={`container large-container ${container}`}>

            <aside>
                <nav className="sidebar-nav">
                    <ul className="sidebar-list">
                        <TabSelector
                            isActive={selectedTab === 'geosciences'}
                            onClick={() => setSelectedTab('geosciences')}
                        >
                            Geosciences
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'drilling'}
                            onClick={() => setSelectedTab('drilling')}
                        >
                            Drilling
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'evaluation'}
                            onClick={() => setSelectedTab('evaluation')}
                        >
                            Evaluation
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'wellTesting'}
                            onClick={() => setSelectedTab('wellTesting')}
                        >
                            Well Testing
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'completions'}
                            onClick={() => setSelectedTab('completions')}
                        >
                            Completions
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'fracturing'}
                            onClick={() => setSelectedTab('fracturing')}
                        >
                            Fracturing
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'workoverSnubbing'}
                            onClick={() => setSelectedTab('workoverSnubbing')}
                        >
                            Workover & Snubbing
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'interventions'}
                            onClick={() => setSelectedTab('interventions')}
                        >
                            Interventions
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'subsea'}
                            onClick={() => setSelectedTab('subsea')}
                        >
                            Subsea
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'productionPPS'}
                            onClick={() => setSelectedTab('productionPPS')}
                        >
                            Production & PPS
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'integratedServices'}
                            onClick={() => setSelectedTab('integratedServices')}
                        >
                            Integrated Services
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'logisticsHSE'}
                            onClick={() => setSelectedTab('logisticsHSE')}
                        >
                            Logistics, HSE & Support
                        </TabSelector>
                        <TabSelector
                            isActive={selectedTab === 'mainstreamDownstream'}
                            onClick={() => setSelectedTab('mainstreamDownstream')}
                        >
                            Mainstream & Downstream
                        </TabSelector>
                    </ul>
                </nav>
            </aside>


            {/*Content*/}
            <div className={`container`} style={{ width: '100%' }}>
                <div className="content">
                    <TabPanel className={tabContent} hidden={selectedTab !== 'geosciences'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Geosciences</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Geologists</li>
                                            <li>Geophysics</li>
                                            <li>Reservoir Engineers</li>
                                            <li>Petroleum Engineers</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>DAQ</li>
                                            <li>Sedimentologists</li>
                                            <li>Seismic Studies</li>
                                            <li>Modellers</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-geoscience.jpg'} alt="GeoSciences" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'drilling'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Drilling</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Management</li>
                                            <li>Superintendents</li>
                                            <li>Engineers</li>
                                            <li>Supervisors</li>
                                            <li>Tool Pushers</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Rig Managers</li>
                                            <li>Drillers/DD</li>
                                            <li>CT Drilling</li>
                                            <li>Rig Crews</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-drilling.jpg'} alt="Drilling" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'evaluation'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Evaluation</h3>
                                <ul className="formatted-list">
                                    <li>MWD/LWD</li>
                                    <li>EL/Wireline Logging</li>
                                    <li>Production Logging Specialists</li>
                                </ul>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-evaluation.jpg'} alt="Evaluation" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'wellTesting'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Well Testing</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Engineers</li>
                                            <li>Coordinators</li>
                                            <li>Supervisors</li>

                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Operators</li>
                                            <li>Data Acquisition/DAQ</li>
                                            <li>Wellbore Clean-up</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-well-test.jpg'} alt="Well Test" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'completions'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Completions</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Management</li>
                                            <li>Superintendents</li>
                                            <li>Engineers</li>
                                            <li>Coordinators</li>
                                            <li>Supervisors</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Smart Completion specialists</li>
                                            <li>PDHG Specialists</li>
                                            <li>Tool hands</li>
                                            <li>Upper & Lower Specialists</li>
                                            <li>Sand Control Specialists</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-completions.jpg'} alt="Completions" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'fracturing'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Fracturing</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Project/Site Managers</li>
                                            <li>Engineers</li>
                                            <li>Trainers</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Coordinators</li>
                                            <li>Supervisors</li>
                                            <li>Operators (Blender,Hydration, Line boss, Sand, Chemicals, Flowback)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-frac.jpg'} alt="Fracturing" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'workoverSnubbing'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Workover & Snubbing</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Management</li>
                                            <li>Superintendents</li>
                                            <li>Project Managers</li>
                                            <li>Coordinators</li>
                                            <li>Engineers</li>
                                            <li>Supervisors</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Jack Operators</li>
                                            <li>Operators</li>
                                            <li>Banksmen</li>
                                            <li>Crane Operators</li>
                                            <li>CAD Design</li>
                                            <li>Hydraulic Mechanics & E-Techs</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-workover.jpg'} alt="Workover" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'interventions'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Interventions</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <p><strong>Coil Tubing</strong></p>
                                        <ul className="formatted-list">
                                            <li>Management</li>
                                            <li>Coordinators</li>
                                            <li>Engineers</li>
                                            <li>Supervisors</li>
                                            <li>Operators</li>
                                            <li>CTD Crews</li>
                                            <li>FSM’s</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <p><strong>Slickline</strong></p>
                                        <ul className="formatted-list">
                                            <li>Management</li>
                                            <li>SQ</li>
                                            <li>Trainers</li>
                                            <li>Coordinators</li>
                                            <li>Engineers</li>
                                            <li>Supervisors</li>
                                            <li>Operators</li>
                                            <li>FSM’s</li>
                                        </ul>
                                    </div>
                                </div>
                                <div style={{ marginTop: '45px' }}>
                                    <p><strong>Thru-Tubing</strong></p>
                                    <ul className="formatted-list">
                                        <li>Engineers</li>
                                        <li>Supervisors</li>
                                        <li>Operators</li>
                                        <li>Tool specialists</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-interventions.jpg'} alt="Interventions" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'subsea'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Subsea</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Management</li>
                                            <li>Coordinators</li>
                                            <li>Engineers</li>
                                            <li>Supervisors</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Technicians</li>
                                            <li>Coordinators</li>
                                            <li>ROV Specialists</li>
                                            <li>IWOC Specialists</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-subsea.jpg'} alt="Subsea" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'productionPPS'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Production & PPS</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Project Managers</li>
                                            <li>Engineers</li>
                                            <li>Supervisors</li>
                                            <li>Technicians</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Coordinators</li>
                                            <li>Engineers</li>
                                            <li>Operators</li>
                                            <li>Technicians</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-pps.jpg'} alt="PPS Production" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'integratedServices'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Integrated Services</h3>
                                <ul className="formatted-list">
                                    <li>Integrated Project Management / Turnkey crews</li>
                                </ul>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-integrated-services.jpg'} alt="Integrated Services" />
                            </div>
                        </div>
                    </TabPanel>


                    <TabPanel className={tabContent} hidden={selectedTab !== 'logisticsHSE'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Logistics, HSE & Support</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <p><strong>Logistics</strong></p>
                                        <ul className="formatted-list">
                                            <li>Coordinators/Supervisors</li>
                                            <li>DTQR</li>
                                            <li>Materials Coordinators/Supervisors</li>
                                            <li>Supply Base Management</li>
                                            <li>Shorebase/Dockside crews</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <p><strong>Support</strong></p>
                                        <ul className="formatted-list">
                                            <li>Contracts Engineers</li>
                                            <li>QAQC</li>
                                            <li>Mechanics</li>
                                            <li>Electrical Technicians</li>
                                        </ul>
                                    </div>
                                </div>
                                <div style={{ marginTop: '45px' }}>
                                    <p><strong>HSE</strong></p>
                                    <ul className="formatted-list">
                                        <li>Management</li>
                                        <li>Supervisors</li>
                                        <li>Advisors</li>
                                        <li>Document controllers</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-logistics-support.jpg'}
                                    alt="Logistics, HSE & Support" />
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel className={tabContent} hidden={selectedTab !== 'mainstreamDownstream'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Mainstream & Downstream</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>TIG Welders</li>
                                            <li>MAG Welders</li>
                                            <li>Electrode Welders</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Mechanical Teams</li>
                                            <li>Pipefitting Teams</li>
                                            <li>HSE</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../oil-and-gas/web/o-g-mainstream-downstream.jpg'}
                                    alt="Mainstream & Downstream" />
                            </div>
                        </div>
                    </TabPanel>

                </div>
            </div>
        </div>
    );
}